import { Button } from "package:/components/elements/Button";
import { Icon } from "package:/components/elements/Icon";
import { navigate } from "package:/components/elements/Link";
import { MobileSafeArea } from "package:/components/elements/MobileSafeArea.jsx";
import { Sheet } from "package:/components/elements/Sheet";
import { Flags } from "package:/components/modules/Flags";
import { Header } from "package:/components/modules/Header";
import { Navigation } from "package:/components/modules/Navigation";
import { Style, setStatusBar } from "package:/composables/theme";
import { Actions } from "~~/packages/actions/lib";
import { useHistory } from "~/composables/useHistory";
import {
  computed,
  defineComponent,
  ref,
  useHead,
  useIsDesktop,
  useLanguage,
  useNuxtApp,
  useRouter,
  useTranslations,
} from "#imports";

// biome-ignore lint/style/noDefaultExport: <explanation>
export default defineComponent(
  (props: { bg: string }, { slots }) => {
    const isDesktop = useIsDesktop();
    const loaded = ref(true);
    const menuOpen = ref(false);

    const flagsOpen = ref(false);
    const clickCount = ref(0);

    const lang = useLanguage();
    const t = useTranslations();

    const historyState = useHistory(() => {
      return {};
    });

    setStatusBar({ style: Style.Dark });

    const router = useRouter();
    router.afterEach((to, from) => {
      if (to.fullPath !== from.fullPath) {
        loaded.value = false;
      }
      if (to.fullPath === from.fullPath) {
        menuOpen.value = false;
      }
    });

    const app = useNuxtApp();
    app.hook("page:finish", () => {
      menuOpen.value = false;
      loaded.value = true;
    });

    const isSubpath = computed(() => {
      return router.currentRoute.value?.name !== "lang";
    });

    Actions.mapShortcut(
      "ctrl+shift+F11",
      Actions.register("flags.open", {
        async run() {
          flagsOpen.value = true;
        },
      }),
    );

    useHead({
      bodyAttrs: {
        class: props.bg === "purple" ? "bg-purple-400" : "bg-green-800",
      },
    });

    return () => (
      <div
        class={[
          "layout-default",
          props.bg === "purple" ? "bg-purple-400" : "bg-green-800",
        ]}
      >
        <Sheet
          position={!isDesktop.value && isSubpath.value ? "left" : "right"}
          enabled={menuOpen.value}
          onBlur={() => {
            menuOpen.value = false;
          }}
        >
          <MobileSafeArea bottom={false} class="relative grid min-h-full">
            <Navigation />

            <Button
              class="absolute bottom-12 left-1/2 h-10 w-10 cursor-default"
              variant="ghost"
              onClick={(e) => {
                clickCount.value++;
                if (clickCount.value >= 5) {
                  flagsOpen.value = true;
                  clickCount.value = 0;
                }
              }}
            />
          </MobileSafeArea>
        </Sheet>

        <div class="pointer-events-none fixed top-5 z-50 w-full text-[1.375rem] lg:top-8">
          <MobileSafeArea>
            <Header
              left={() =>
                !menuOpen.value &&
                !isDesktop.value &&
                isSubpath.value && (
                  <div>
                    <Button
                      class="pointer-events-auto text-xl"
                      variant="iconDark"
                      label={t("header.openMenu")}
                      onClick={() => {
                        // menuOpen.value = true;
                        const routeName = String(router.currentRoute.value?.name);
                        if (
                          historyState.value?.back &&
                          /news|events/.test(routeName) &&
                          router.currentRoute.value?.params.slug
                        ) {
                          router.back();
                        } else if (
                          !historyState.value?.back &&
                          router.currentRoute.value?.params.feed
                        ) {
                          navigate(
                            `/${lang.value}/${router.currentRoute.value?.params.feed}`,
                          );
                        } else {
                          menuOpen.value = true;
                        }
                      }}
                    >
                      <Icon name="arrow-left" aria-hidden />
                    </Button>
                  </div>
                )
              }
              menu={() =>
                !isDesktop.value && isSubpath.value ? (
                  <Button
                    class="pointer-events-auto text-md"
                    variant="iconDark"
                    label={t("header.closeMenu")}
                    onClick={() => {
                      navigate({
                        name: "lang",
                        params: {
                          lang: lang.value,
                        },
                      });
                    }}
                  >
                    <Icon name="close" aria-hidden />
                  </Button>
                ) : (
                  <Button
                    class="pointer-events-auto text-xl"
                    variant="iconDark"
                    label={t("header.openMenu")}
                    onClick={() => {
                      menuOpen.value = !menuOpen.value;
                    }}
                  >
                    {menuOpen.value ? (
                      <Icon class="text-md" name="close" aria-hidden />
                    ) : (
                      <Icon name="menu" aria-hidden />
                    )}
                  </Button>
                )
              }
            />
          </MobileSafeArea>
        </div>

        <div
          class={[
            "transition-opacity",
            /* hide the old page as long as we are loading the new one, to give feedback of something happening */
            loaded.value ? "" : "opacity-0",
          ]}
        >
          {slots.default?.()}
        </div>

        {flagsOpen.value && (
          <Flags
            onClose={() => {
              flagsOpen.value = false;
            }}
          />
        )}
      </div>
    );
  },
  {
    props: ["bg"],
  },
);
