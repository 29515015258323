import { buttonVariants } from "package:/components/elements/Button";
import { Icon } from "package:/components/elements/Icon";
import { Link } from "package:/components/elements/Link";
import {
  defineComponent,
  useIsDesktop,
  useLanguage,
  useRouter,
  useTranslations,
} from "#imports";

export const Header = defineComponent(
  (props: {
    left?: () => any;
    menu?: () => any;
  }) => {
    const t = useTranslations();
    const lang = useLanguage();
    const isDesktop = useIsDesktop();
    const router = useRouter();

    return () => (
      <header class="container pointer-events-none flex max-w-full text-text">
        {props.left?.()}

        {isDesktop.value && (
          <Link
            class="pointer-events-auto"
            to={{
              name: "lang",
              params: {
                lang: lang.value,
              },
            }}
            onClick={() => {
              // scroll to the top of the page on home
              if (router.currentRoute.value?.name === "lang") {
                document.querySelector("section")?.scrollIntoView({
                  // behavior: "smooth" // doesnt work because of scroll-snap
                });
              }
            }}
            label={t("header.linkToHome")}
          >
            <img width="80" height="50" src="/logo.svg" alt="Green Trails" />
          </Link>
        )}

        <div class="ml-auto flex justify-between">
          <ul class="flex gap-3">
            {isDesktop.value && (
              <li>
                <Link
                  class={["pointer-events-auto", buttonVariants.big]}
                  to={{
                    name: "lang-map",
                    params: {
                      lang: lang.value,
                    },
                    query: {
                      view: null,
                    },
                  }}
                >
                  <Icon name="map" aria-hidden="true" />
                  <span>{t("button.map")}</span>
                </Link>
              </li>
            )}

            <li>{props.menu?.()}</li>
          </ul>
        </div>
      </header>
    );
  },
  {
    name: "Header",
    props: ["left", "menu"],
  },
);
