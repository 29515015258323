import { Actions } from "actions";
import { defineComponent, useCookie, useFlags } from "#imports";
import { Button } from "../elements/Button";
import { Checkbox } from "../elements/Checkbox";
import "../elements/Drawer";
import { error } from "package:/utils";
import { Select, SelectItem } from "../elements/Select";
import { toast } from "../elements/Toasts";
import { PageLayer } from "./PageLayer";

export const Flags = defineComponent(
  (props: {
    onClose: () => void;
  }) => {
    const flags = useFlags();
    const cookie = useCookie("flags");

    const reset = () => {
      cookie.value = JSON.stringify({});
      location.reload();
    };

    const showUpdateDialog = () => {
      try {
        Actions.run("updatedialog.open");
      } catch (e) {
        error("action not found", "err", e);
      }
    };

    const showToast = () => {
      toast({
        message: "Error",
        time: 500000,
        variant: "error",
      });
      toast({
        message: "Information",
        time: 500000,
        variant: "transparent",
      });
      toast({
        message: "Default",
        time: 500000,
      });
    };

    return () => (
      <div
        class="fixed top-0 bottom-0 left-0 z-50 w-full overflow-hidden pt-[50px]"
        style={{
          "--color-bg": "var(--color-green-800)",
        }}
      >
        <PageLayer
          showClose={true}
          drawerDisabled={true}
          drawerDynamicHeight={true}
          variant="dark"
          onClose={props.onClose}
          class="pointer-events-none absolute top-0 left-0 z-50 h-full w-full"
        >
          <div class="h-full bg-green-800 px-5 lg:pt-28">
            <Button onClick={() => showToast()}>Show Toast</Button>
            <Button onClick={() => showUpdateDialog()}>Show Update Dialog</Button>

            <hr class="my-6 border-green-500" />

            <form
              onSubmit={(e) => {
                // @ts-ignore
                const data = new FormData(e.currentTarget);
                const custom_flags = {};

                for (const [key, value] of data.entries()) {
                  if (value) {
                    custom_flags[key] =
                      value === "on" ? true : value === "default" ? undefined : value;
                  }
                }

                cookie.value = JSON.stringify(custom_flags);
                location.reload();

                e.preventDefault();
              }}
            >
              {Object.keys(flags).map((key) => {
                if (key === "api_origin") {
                  return (
                    <div class="mb-4">
                      <label class="pr-2">API Origin</label>
                      <Select placeholder="Api Origin" required={false} name="api_origin">
                        <SelectItem value="">default</SelectItem>
                        <SelectItem value="https://api.greentrails.de">
                          greentrails.de
                        </SelectItem>
                        <SelectItem value="https://api.staging.gt.s-v.io">
                          staging.gt.s-v.io
                        </SelectItem>
                        <SelectItem value="https://api.gt.test">gt.test</SelectItem>
                      </Select>
                    </div>
                  );
                }
                return (
                  <Checkbox class="mb-4" key={key} id={key} checked={flags[key]}>
                    <p>{key}</p>
                  </Checkbox>
                );
              })}

              <Button type="submit">Confirm and reload</Button>
              <Button
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </Button>
            </form>
          </div>
        </PageLayer>
      </div>
    );
  },
  {
    name: "Flags",
    props: ["onClose"],
  },
);
