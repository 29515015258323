import { Button, buttonVariants } from "package:/components/elements/Button";
import { Dynamic } from "package:/components/elements/Dynamic";
import { Icon } from "package:/components/elements/Icon";
import { Link } from "package:/components/elements/Link";
import { SocialLinks } from "package:/components/modules/SocialLinks";
import { useApi } from "package:/composables/useApi";
import { useVersion } from "package:/composables/useVersion";
import {
  defineComponent,
  useEnv,
  useFlags,
  useLanguage,
  useTranslations,
} from "#imports";
import { Picture } from "~/components/elements/Picture";

export const Navigation = defineComponent(
  () => {
    const t = useTranslations();
    const lang = useLanguage();
    const flags = useFlags();
    const env = useEnv();
    const version = useVersion();

    const data = useApi((api) => {
      return api.page.pagesNavigation();
    });

    return () => (
      <div class="flex min-h-full flex-col justify-between text-white">
        <div class="px-4 py-28">
          {flags.profile && (
            <div class="space-y-4 pb-14 last:pb-0">
              {false ? (
                <div class="mt-7">
                  <div class="text-xl">{t("account.username")}</div>
                  <Link
                    class="flex w-full items-center justify-between font-light data-[active]:underline"
                    variant="inline"
                    to={{
                      name: "lang-profile",
                      params: { lang: lang.value },
                    }}
                  >
                    <span>{t("navigation.profile.link")}</span>
                  </Link>
                </div>
              ) : (
                <div class="mt-7 flex flex-wrap gap-2">
                  <Button variant="big">{t("account.register.label")}</Button>
                  <Button variant="bigLight">{t("account.login.label")}</Button>
                </div>
              )}
            </div>
          )}

          {flags.bookmark && (
            <div class="space-y-4 pb-8">
              <Link
                class="flex w-full items-center justify-between font-light text-xl data-[active]:underline"
                variant="plain"
                to={{
                  name: "lang-saved",
                  params: { lang: lang.value },
                }}
              >
                <span>{t("navigation.saved")}</span>
                <Icon name="arrow-right" aria-hidden="true" class="text-[0.8em]" />
              </Link>
            </div>
          )}

          {flags.news && (
            <div class="space-y-4 pb-8">
              <Link
                class="flex w-full items-center justify-between font-light text-xl data-[active]:underline"
                variant="plain"
                to={{
                  name: "lang-news",
                  params: { lang: lang.value },
                }}
              >
                <span>{t("navigation.news")}</span>
                <Icon name="arrow-right" aria-hidden="true" class="text-[0.8em]" />
              </Link>
            </div>
          )}

          {flags.events && (
            <div class="space-y-4 pb-14">
              <Link
                class="flex w-full items-center justify-between font-light text-xl data-[active]:underline"
                variant="plain"
                to={{
                  name: "lang-events",
                  params: { lang: lang.value },
                }}
              >
                <span>{t("navigation.events")}</span>
                <Icon name="arrow-right" aria-hidden="true" class="text-[0.8em]" />
              </Link>
            </div>
          )}

          {flags.offline && (
            <div class="space-y-4 pb-14">
              <Link
                class="flex w-full items-center justify-between font-light text-xl data-[active]:underline"
                variant="plain"
                to={{
                  name: "lang-offline",
                  params: { lang: lang.value },
                }}
              >
                <span>{t("navigation.offline")}</span>
                <Icon name="arrow-right" aria-hidden="true" class="text-[0.8em]" />
              </Link>
            </div>
          )}

          <Dynamic data={data}>
            {data.data.value?.data.map((category) => {
              return (
                <div
                  class="space-y-4 pb-14 last:pb-0"
                  key={`navigation-category-${category.title}`}
                >
                  <h2 class="font-bold text-xl">{category.title}</h2>
                  <div class="mt-7 space-y-4">
                    {category.children.map((item) => {
                      return (
                        <Link
                          key={`navigation-item-${item.slug}`}
                          class="flex w-full items-center justify-between font-light text-xl data-[active]:underline"
                          variant="plain"
                          to={{
                            name: "lang-slug",
                            params: { lang: lang.value, slug: item.slug },
                          }}
                        >
                          <span>{item.title}</span>
                          <Icon
                            name="arrow-right"
                            aria-hidden="true"
                            class="text-[0.8em]"
                          />
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Dynamic>

          {flags.report ? (
            <div class="space-y-4 pb-14">
              <Link
                class={[buttonVariants.bigLight, "w-full justify-center"]}
                to={{
                  name: "lang-report",
                  params: { lang: lang.value },
                }}
              >
                {t("report.link.label")}
              </Link>
            </div>
          ) : ""}
        </div>

        <div class="bg-green-500 py-14">
          <div class="px-4">
            <h2 class="font-bold text-green-200">{t("navigation.contact")}</h2>
            <Link
              class="mt-7 flex w-full items-center gap-4 text-base text-link"
              variant="inline"
              href={`tel:+${t("navigation.phone")}`}
            >
              <Icon name="phone" aria-hidden="true" class="text-[1.1em]" />
              <span>{t("navigation.phone")}</span>
            </Link>
            <Link
              class="mt-7 flex w-full items-center gap-4 text-base text-link"
              variant="inline"
              href={`mailto:${t("navigation.mail")}`}
            >
              <Icon name="mail" aria-hidden="true" class="text-[1.1em]" />
              <span>{t("navigation.mail")}</span>
            </Link>
          </div>

          <div class="mt-14 px-4">
            <h2 class="mb-5 font-bold text-green-200">{t("misc.sponsors")}</h2>
            <Picture
              imgClass="w-full"
              height={45}
              src="/logos/logos.png"
              alt={t("misc.sponsors")}
            />
          </div>

          <div class="mt-14 flex gap-4 px-4">
            <SocialLinks variant="darker" />
          </div>

          <div class="mt-7 w-full select-none px-4 text-green-200 text-xs">
            {t("navigation.copyright")}
          </div>

          {(env.PLATFORM === "native" || version.isDev) && (
            <div class="mt-2 w-full px-4 text-green-200 text-xs">
              VERSION <span>{version.name}</span>
            </div>
          )}
        </div>
      </div>
    );
  },
  {
    name: "Navigation",
  },
);
