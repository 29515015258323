import { twMerge } from "tailwind-merge";
import { defineComponent, effect, ref } from "#imports";
import "@sv/elements/blur";
import "@sv/elements/portal";

export const Sheet = defineComponent(
  (
    props: {
      position?: "left" | "right";
      enabled: boolean;
      onBlur: (ev?: Event) => void;
    },
    { slots },
  ) => {
    return () => (
      <a-blur
        onBlur={props.onBlur}
        enabled={props.enabled ? true : undefined}
        class="group pointer-events-none absolute top-0 left-0 z-50 block h-full w-full transition-colors [&[enabled]]:bg-[hsl(158.33deg_100%_7.06%_/_15%)]"
      >
        <div
          class={twMerge(
            "pointer-events-auto fixed top-0 h-full w-full sm:w-[24.375rem]",
            props.position === "left" ? "left-0" : "right-0",
          )}
        >
          <div
            class={twMerge(
              "absolute h-full w-full overflow-auto bg-green-900 transition-transform",
              props.position === "left"
                ? "right-full group-[&[enabled]]:translate-x-full"
                : "group-[&[enabled]]:-translate-x-full left-full",
            )}
          >
            {slots.default?.()}
          </div>
        </div>
      </a-blur>
    );
  },
  {
    name: "Sheet",
    props: ["position", "enabled", "onBlur"],
  },
);
